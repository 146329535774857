:root {
  --body-color: #b0bfc2;
  --header-color: #f9f8f6;
  --dark-color: #2b3433;
  --primary-color: #f35a5a;
  --primary-light-color: #f35a5a91;
  --secondary-color: #2eabe3;
  --light-color: #dae7eb;
  --text-color: #000000b3;
  --white-color: #fff;
  --black-color: #000;
  --grey-color: #777;
  --red-color: #dc3545;
  --hover-color: #ff00001a;
}

body {
  margin: 0;
  font-family: "Inter", "circular", "sans-serif", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #00000003;
  /* background-color: var(--body-color); */
}

.mainWrapper {
  max-width: 1300px;
  margin-inline: auto;
}

.mainBody {
  min-height: calc(100vh - 454px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white-color);
  position: relative;
}

/* .mainBody section {
  min-height: calc(100vh - 90px);
} */

/********************************** paginaiton ***********************/

.pagination {
  margin: 0;
}

.page-link {
  background-color: transparent;
  color: var(--white-color);
  z-index: 0 !important;
}

.darkPagination .page-link {
  color: var(--black-color);
  border-color: var(--black-color);
}

.page-link:hover,
.page-link:focus,
.page-link:active {
  background-color: var(--light-color);
  color: var(--black-color);
  box-shadow: none;
  border-color: var(--white-color);
}

.darkPagination .page-link:hover,
.darkPagination .page-link:focus,
.darkPagination .page-link:active {
  background-color: var(--light-color);
  color: var(--black-color);
  border-color: var(--black-color);
}

.page-item.active .page-link {
  background-color: var(--secondary-color);
  color: var(--white-color);
  border-color: var(--white-color);
}

.darkPagination .page-item.active .page-link {
  background-color: var(--primary-color);
  border-color: var(--black-color);
}

.page-item:first-child .page-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/********************************** scrollbar ***********************/

*::-webkit-scrollbar {
  /* width: 8px; */
  width: 0;
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

*::-webkit-scrollbar-track {
  box-shadow: 0 0 2px var(--primary-color);
}

/********************************** ck-editor ***********************/

.ck-editor__top {
  border: 1px solid var(--black-color) !important;
  border-bottom: none !important;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  background: #fff !important;
}

.ck-sticky-panel__content {
  border: none !important;
  border-radius: 0;
}

.ck-toolbar {
  background: transparent !important;
}

.ck-toolbar .ck-color-grids-fragment button,
.ck-toolbar .ck-dropdown__panel button,
.ck-toolbar li button {
  color: var(--black-color) !important;
}

/* .ck-toolbar button:hover,
.ck-toolbar button:active,
.ck-toolbar button:focus {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
} */

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background-color: #bdbdbd !important;
  color: var(--text-color) !important;
}

.ck-editor__editable {
  min-height: 200px;
  border: 1px solid var(--black-color) !important;
  outline: none !important;
  background: transparent !important;
}

.ck-balloon-panel {
  display: none !important;
}

/********************************** common ***********************/

.dropdown-toggle:after {
  display: none;
}

.theme-clr {
  color: var(--primary-color);
}

.w-fit {
  width: fit-content;
}

.gap-10 {
  gap: 10px;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.border-secondary {
  border-color: var(--secondary-color) !important;
}

.border-dark {
  border-bottom: 4px solid var(--black-color);
}

.border-strong {
  border-width: 2px;
}

.border-bottom-strong {
  border-bottom: 2px solid var(--black-color);
}

.border-secondary-light {
  border: 1px solid var(--light-color);
}

.border-enclined {
  width: 70px;
  border: 4px solid var(--primary-color);
  border-top-right-radius: 89px;
}

.shadow-box {
  box-shadow: 0 0 2px 1px grey;
}

.truncate-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.truncate-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.growLoader {
  width: 18px;
  height: 18px;
}

.checkbox {
  width: 15px;
  height: 15px;
}

.rememberMe {
  width: 15px;
  height: 15px;
}

.rememberMe:checked {
  accent-color: var(--dark-color);
}

.checkbox:checked {
  accent-color: var(--primary-color);
}

.bg-primary-default {
  background-color: var(--bs-primary) !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-secondary {
  background-color: var(--secondary-color) !important;
}

.bg-secondary-light {
  background-color: var(--light-color);
}

.bg-white {
  background-color: var(--white-color) !important;
}

.bg-header {
  background-color: var(--header-color) !important;
}

.bg-primary-light {
  background-color: var(--hover-color);
}

.pointer {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  opacity: 70%;
}

.primaryBtn {
  color: var(--white-color);
  outline: none;
  background: linear-gradient(
    to right,
    var(--primary-color) 0%,
    var(--primary-color) 50%,
    var(--secondary-color) 50%,
    var(--secondary-color) 100%
  );
  background-size: 200% 100%;
  background-position: 0 0;
  /* background-size: 210% 100%;
  background-position: 99% 0; */
  transition: background-position 0.3s;
  border: 2px solid var(--primary-color);
}

.primaryBtn:hover,
.primaryBtn:active {
  /* color: var(--black-color); */
  background-position: -100% 0;
  border: 2px solid var(--secondary-color);
}

.secondaryBtn {
  /* color: var(--black-color); */
  outline: none;
  background: var(--secondary-color);
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  min-width: 70px;
}

.secondaryBtn:hover,
.secondaryBtn:active {
  color: var(--black-color);
  background-position: 0 0;
}

.darkBtn {
  color: var(--white-color);
  outline: none;
  background: linear-gradient(
    to right,
    var(--dark-color) 0%,
    var(--dark-color) 50%,
    var(--body-color) 50%,
    var(--body-color) 100%
  );
  background-size: 200% 100%;
  background-position: 0 0;
  transition: background-position 0.3s;
  border: 2px solid var(--dark-color);
}

.darkBtn:hover,
.darkBtn:active {
  color: var(--black-color);
  background-position: -100% 0;
  border: 2px solid var(--dark-color);
}

.textBtn {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--primary-color);
}

.textBtn:hover {
  color: var(--primary-color);
}

.outlineBtn {
  outline: none;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  padding-inline: 12px;
}

.backBtn {
  width: 25px;
  height: 30px;
  background: var(--white-color);
  box-shadow: 0px 0px 2px var(--text-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.transparent_select__control {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid var(--primary-color) !important;
}

.mySelect__option:hover,
.mySelect__option:active,
.mySelect__option:focus,
.mySelect__option--is-selected {
  background-color: var(--primary-color) !important;
  color: var(--white-color);
}

.mySelect__value-container {
  padding-block: 0px !important;
}
.mySelect__input-container {
  padding: 0px !important;
  margin: 0px !important;
}

.mySelect__control {
  background-color: transparent !important;
}

.darkSelect__option {
  color: var(--primary-color) !important;
}

.darkSelect__single-value,
.darkSelect__input {
  color: var(--black-color) !important;
}

.darkSelect__control {
  background-color: transparent !important;
  border: 1px solid var(--primary-color) !important;
}

.darkSelect__indicator {
  color: var(--primary-color) !important;
}

/* .transparent_select__option--is-focused {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
} */

.userImage {
  width: 100px !important;
  height: 100px;
}

.img-large {
  width: 100px !important;
  height: 100px;
}

.img-medium {
  min-width: 60px;
  width: 60px !important;
  height: 60px;
}

.img-small {
  width: 40px !important;
  height: 40px;
}

.muted {
  opacity: 50%;
}

.extraSmall {
  font-size: 10px;
}

.small {
  font-size: 12px;
}

.text-primary-light {
  color: var(--primary-light-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.text-color {
  color: var(--text-color);
}

.text-small {
  font-size: 12px;
}

.text-medium {
  font-size: 14px;
}

.text-large {
  font-size: 18px;
}

.text-dark {
  color: var(--text-color);
}

.extraLarge {
  font-size: 20px;
}

.fw-normal {
  font-weight: normal;
}

.fw-sbold {
  font-weight: 500;
}

.fw-bold {
  font-weight: bold;
}

.optionIcon {
  display: flex;
}

.optionIcon svg {
  width: 20px;
  height: 20px;
}

.myInput {
  background-color: transparent;
  border: 1px solid var(--black-color);
  box-shadow: none !important;
}

.myInput:focus {
  border: 1px solid var(--black-color);
}

/********************************** ck editor ***********************/
.cke_top {
  background-color: var(--secondary-color) !important;
}

a.cke_button_off:hover,
a.cke_button_off:focus,
a.cke_button_off:active {
  background: var(--light-color) !important;
  border: 1px solid var(--light-color) !important;
  padding: 3px 5px;
}

.cke_combo_on a.cke_combo_button,
.cke_combo_off a.cke_combo_button:hover,
.cke_combo_off a.cke_combo_button:focus,
.cke_combo_off a.cke_combo_button:active {
  background: var(--light-color) !important;
  border: 1px solid var(--light-color) !important;
}

.cke_bottom {
  display: none !important;
}

.cke_contents .cke_reset body {
  background-color: var(--light-color) !important;
}

/********************************** Auth ***********************/

.authContainer {
  height: 100vh;
  /* min-height: calc(100vh - 90px); */
}

.authSideImageContainer {
  position: relative;
  height: 100%;
  /* background-color: var(--dark-color); */
  background-color: var(--primary-color);
}

.authSideImageContainer > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  color: #fff;
}

.authFormContainer {
  display: grid;
  place-items: center;
  background-color: var(--white-color);
  /* background-color: var(--light-color); */
  overflow: auto;
  height: 100%;
}

.authFormContainer > div {
  min-width: 250px;
  max-width: 400px;
  margin-inline: auto;
}

@media (max-width: 550px) {
  .authFormContainer > div {
    margin-inline: 20px;
  }
}

.eye_btn {
  position: absolute;
  right: 0;
  border: 0;
  bottom: 50%;
  transform: translate(0px, 50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

/**********************************header ***********************/
.headerContainer {
  /* box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1); */
  position: sticky;
  top: 0;
  z-index: 999;
  /* background-color: var(--white-color); */
  background-color: var(--header-color);
}

.header {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-inline: auto;
}

.mainLogo {
  width: 100%;
  max-height: 45px;
  max-width: 60px;
  padding: 6px;
}

.headerLogoName {
  font-weight: 500;
  color: var(--primary-color);
}

.headerIcon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.headerLink {
  margin-inline: 5px;
  padding-inline: 5px;
  color: var(--text-color);
  transition: transform 0.5s ease;
}

.headerLink:hover,
.headerLink:active,
.headerLink:focus {
  color: var(--primary-color);
  /* font-weight: 600; */
  transform: scale(1.2);
}

/* ****************** mobile bottom tab ************************ */

.mobileBottomTabWrapper {
  width: 100%;
  height: 53px;
  margin-top: 20px;
  position: sticky;
  bottom: 0;
  background-color: var(--white-color);
  background-image: url("/public/images/mobileBottomTab.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 9;
}

.bottomTabActions svg {
  width: 25px;
  height: 25px;
}

.mobileAddStoryBtn {
  color: var(--white-color) !important;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -38px);
  background-color: var(--primary-color);
  font-size: 26px;
}

/********************************** footer ***********************/

.footer {
  height: 45px;
  width: 100%;
  color: var(--white-color);
  background: var(--primary-color);
  box-shadow: 0 -2px 3px -1px rgba(0, 0, 0, 0.1);
}

/********************************** home ***********************/
.homeBanner {
  background-color: var(--header-color);
}

.homeCategoryTab {
  transition: transform 0.5s ease;
}
.homeCategoryTab:first-child {
  margin-left: 0.5rem;
}
.homeCategoryTab:last-child {
  margin-right: 0.5rem;
}

.homeCategoryTab:hover {
  color: var(--primary-color);
  transform: scale(1.2);
}

@media (max-width: 600px) {
  .homeCategoryTab:hover {
    transform: scale(1.1);
  }
}

.activeTab {
  background-color: var(--primary-color);
  color: var(--white-color) !important;
}

.homeStorySlider .slick-dots {
  bottom: -40px;
  white-space: nowrap;
  overflow: auto;
  max-width: 150px;
  transform: translate(-50%, 0);
  left: 50%;
}

.homeStorySlider .slick-dots li button::before {
  font-size: 14px !important;
  color: var(--grey-color);
}

.homeStorySlider .slick-dots li.slick-active button::before {
  color: var(--primary-color);
}

.homeGetStarted {
  background-image: url("/public/images/people.webp");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homeGetStarted > div {
  background-color: #ffffff85;
  width: fit-content;
}

@media screen and (max-width: 576px) {
  .homeGetStarted > div {
    width: 100%;
  }
}

.homeSocialIcon {
  background: var(--primary-color);
  border-radius: 50%;
  padding: 6px;
  text-align: center;
  vertical-align: middle;
  margin-inline: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.homeSocialIcon svg {
  width: 20px;
  height: 20px;
  color: var(--white-color);
}

.homeStoryCard {
  border-radius: 4px;
  border: 1px solid var(--primary-light-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: start;
  padding-block: 12px;
  transition: 0.6s;
  height: 230px;
}

.homeStoryCardWidth {
  min-width: 320px;
  max-width: 320px;
}

@media (max-width: 400px) {
  .homeStoryCardWidth {
    min-width: 250px;
    max-width: 250px;
  }
}

.homeStoryCard:hover {
  box-shadow: 0 0px 8px #0000002b;
  border: 1px solid transparent;
  background-color: var(--primary-color);
  color: var(--white-color);
  scale: 1.05;
  border-radius: 8px !important;
}

.storyDesc * {
  color: var(--text-color);
  background-color: transparent !important;
}

.storyDesc-dark * {
  color: #000 !important;
}

.storyDesc > *,
.storyDesc-dark > * {
  margin: 0;
}

.curvatureContainer {
  background-color: var(--primary-color);
  height: 35px;
  display: flex;
  align-items: end;
  justify-content: center;
  /* border-bottom: 1px solid var(--grey-color); */
}

.curvatureContainer div {
  background-color: var(--white-color);
  /* background-color: var(--secondary-color); */
  height: 25px;
  width: 100%;
  display: flex;
}

.curvatureContainer div:first-child {
  justify-content: end;
}

.curvatureContainer div:nth-child(2) {
  justify-content: start;
}

#curved-corner-topleft,
#curved-corner-topright,
#curved-corner-bottomleft,
#curved-corner-bottomright {
  width: 70px;
  height: 25px;
  overflow: hidden;
  position: relative;
}

#curved-corner-topleft:before,
#curved-corner-topright:before,
#curved-corner-bottomleft:before,
#curved-corner-bottomright:before {
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  border-radius: 50%;
}

#curved-corner-topleft:before {
  top: 0;
  left: 0;
  box-shadow: -20px -20px 0 0 var(--primary-color);
}

#curved-corner-topright:before {
  top: 0;
  right: 0;
  box-shadow: 20px -20px 0 0 var(--primary-color);
}

#curved-corner-bottomleft:before {
  bottom: 0;
  left: 0;
  box-shadow: -30px 20px 0 0 var(--light-color);
}

#curved-corner-bottomright:before {
  bottom: 0;
  right: 0;
  box-shadow: 30px 20px 0 0 var(--light-color);
}

.lowerCurvatureContainer {
  background-color: var(--light-color);
  align-items: start;
  border-bottom: 2px solid var(--black-color);
}

/********************************** Story list ***********************/

.storyDetailDesc * {
  background-color: var(--white-color) !important;
}

.storiesListHeader {
  background-color: var(--header-color);
  box-shadow: 0 2px 3px -1px #fff;
}

@media (max-width: 500px) {
  .storiesListHeader div {
    display: block !important;
  }

  .storiesListHeader button {
    margin-block: 6px;
  }
}

.mainStoryCard {
  background-color: var(--white-color);
  box-shadow: 0 6px 7px -4px #16101042, 0 0 2px #0000001a;
  transition: 0.5s;
}

.mainStoryCard:hover {
  background-color: var(--primary-color);
  color: var(--white-color) !important;
}

.mainStoryCard .storyDesc * {
  background-color: transparent !important;
}

.mainStoryCard:hover .storyAction {
  color: var(--text-color);
}

.mainStoryCard:has(.mainStoryCardHeader > div:hover),
.mainStoryCard:has(.mainStoryCardAction:hover),
.mainStoryCard:has(.reactionContainer:hover) {
  background-color: var(--white-color) !important;
  color: var(--text-color) !important;
}

.mainStoryCardHeader > div {
  transition: transform 0.3s;
}

.mainStoryCardHeader > div:first-child:hover {
  color: var(--red-color);
  font-weight: 500;
  transform: scale(1.05);
}

.storyCardHeaderActions .dropdown-toggle {
  background-color: var(--header-color) !important;
}

.storyCardHeaderActions .dropdown-menu p:last-child {
  padding-bottom: 8px;
}

.storyCardHeaderActions .dropdown-menu p:first-child {
  padding-top: 8px;
}

.reactionOptionContainer {
  opacity: 0;
  transition: 0.8s;
}

/* .reactionContainer:hover .reactionOptionContainer {
  opacity: 1;
} */

.reactionOptionContainer {
  position: absolute;
  top: -38px;
  background: #fff;
  left: -36px;
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0 0 3px var(--grey-color);
}

.reactionOptionContainer span {
  transition: 0.3s;
}

.reactionOptionContainer span:hover {
  transform: scale(1.3);
}

.reactedIcon svg {
  width: 20px;
  height: 20px;
}

.storyFilters select {
  width: fit-content;
}

.storyFilterContainer {
  min-width: 170px;
}

.sortingSelect {
  min-width: 120px;
}

.storyAction p {
  width: fit-content;
  margin-inline: 6px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.storyAction p:first-child {
  margin-left: 0px;
}

.storyAction svg {
  margin-inline: 4px;
}

.ourStory img {
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.ourMission img {
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

/* ************************** story detail *********************** */

.storyDetailsContainer .dropdown-item:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}

/* *************************** add story **************************** */

.addStoryWrapper {
  max-width: 900px;
}

.addStoryWrapper .feelingContainer {
  padding: 10px 4px;
  text-align: center;
  gap: 20px;
}
.addStoryWrapper .feelingContainer > div {
  transition: 0.3s;
}
.addStoryWrapper .feelingContainer > div:hover {
  transform: scale(1.15);
}

.addStoryWrapper .feelingContainer > div:hover .iconRegular {
  display: none !important;
}
.addStoryWrapper .feelingContainer > div:hover .iconFilled {
  display: inline-block !important;
}

.addStoryWrapper .feelingContainer p:first-child svg {
  width: 41px;
  height: 42px;
}

/* ***************************** profile ************************************** */
.myAccountDrawer {
  z-index: 999 !important;
}

.myAccountDrawer * {
  color: var(--black-color) !important;
}

.subHeaderContainer {
  position: sticky;
  top: 45px;
  z-index: 9;
}

.profileOptions {
  max-width: 280px;
  width: 100%;
  overflow: auto;
  border-right: 1px solid var(--black-color);
  max-height: calc(100vh - 100px);
}

@media (min-width: 1000px) {
  .headerIconMobile {
    display: none;
  }
}

@media (max-width: 992px) {
  .profileOptions {
    display: none;
    max-height: 100vh;
  }

  .headerIconDesktop {
    display: none;
  }
}

.profileItemsList p {
  cursor: pointer;
  padding-left: 8px;
}

.profileItem {
  transition: 0.5s;
}

.profileItem:hover {
  transform: scale(1.05);
  color: var(--primary-color);
  border-color: var(--primary-color) !important;
}

.profileStoriesList {
  background-color: var(--secondary-color);
  color: var(--black-color);
  overflow: auto;
  max-height: calc(100vh - 100px);
}

/* *************************** update profile ********************************* */
.updateProfileContainer {
  max-width: 500px;
  margin-inline: auto;
  display: grid;
  place-items: center;
}

.profileContainer {
  height: calc(100vh - 100px);
}

.updateAvatarIcon {
  background-color: var(--primary-color);
  right: 0px;
  bottom: 4px;
  width: 24px;
  height: 24px;
  padding: 5px;
  color: var(--white-color);
}

/* *************************** Cousel ********************************* */

.slick-prev,
.slick-next {
  width: 30px;
  height: 70px;
  background: var(--light-color) !important;
  border-radius: 3px;
}

.slick-prev:before,
.slick-next:before {
  opacity: 100%;
}

.slick-prev:hover,
.slick-prev:focus {
  border: 1px solid #cdcdcd;
  border-left: 0;
}

.slick-next:hover,
.slick-next:focus {
  border: 1px solid #cdcdcd;
  border-right: 0;
}

.slick-next {
  right: -26px;
}

@media (max-width: 996px) {
  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }
}

.bookmarkModelIcon {
  width: 150px;
  height: 150px;
}

.modelBookmarkIcon {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%, 0px);
}

.modelBookmarkIcon svg {
  width: 50px;
  height: 50px;
  background-color: var(--white-color);
  padding: 8px;
  border-radius: 4px;
}

.reportStoryPop .modal-dialog {
  max-width: 400px;
  margin-inline: auto;
}

/* ****************************** tabs ************************************* */

/* bordered tabs */
.borderPillsTab {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex-wrap: nowrap;
  overflow: scroll;
  scrollbar-width: none;
}

.borderPillsTab .nav-link {
  font-size: 14px;
  border: 1px solid #2b343378;
  border-bottom-width: 3px !important;
  height: 40px;
}

.borderPillsTab > .nav-item:first-child .nav-link {
  border-top-left-radius: 8px !important;
}

.borderPillsTab > .nav-item:last-child .nav-link {
  border-top-right-radius: 8px !important;
}

.borderPillsTab .nav-link.active {
  border-color: var(--primary-color);
  color: var(--primary-color) !important;
  font-weight: 600;
}

.reactionIconFromList svg {
  width: 30px;
  height: 30px;
}

.w-230 {
  width: 230px !important;
}

/* ********************************** loader ***************************************** */

.loader {
  --w: 10ch;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  line-height: 1.4em;
  letter-spacing: var(--w);
  width: var(--w);
  overflow: hidden;
  white-space: nowrap;
  color: #0000;
  text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000, calc(-2 * var(--w)) 0 #000,
    calc(-3 * var(--w)) 0 #000, calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
    calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000, calc(-8 * var(--w)) 0 #000,
    calc(-9 * var(--w)) 0 #000;
  animation: l20 2s infinite linear;
}

.loader:before {
  content: "Loading...";
}

.hide-loader {
  margin-top: -50px;
  transition: 1s;
}

@keyframes l20 {
  0%,
  100% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000, calc(-2 * var(--w)) 0 #000,
      calc(-3 * var(--w)) 0 #000, calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000, calc(-8 * var(--w)) 0 #000,
      calc(-9 * var(--w)) 0 #000;
  }

  9.09% {
    text-shadow: calc(0 * var(--w)) -10px #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000, calc(-4 * var(--w)) 0 #000,
      calc(-5 * var(--w)) 0 #000, calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }

  18.18% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) -10px #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000, calc(-4 * var(--w)) 0 #000,
      calc(-5 * var(--w)) 0 #000, calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }

  27.27% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) -10px #000, calc(-3 * var(--w)) 0 #000, calc(-4 * var(--w)) 0 #000,
      calc(-5 * var(--w)) 0 #000, calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }

  36.36% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000, calc(-2 * var(--w)) 0 #000,
      calc(-3 * var(--w)) -10px #000, calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000, calc(-8 * var(--w)) 0 #000,
      calc(-9 * var(--w)) 0 #000;
  }

  45.45% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000, calc(-2 * var(--w)) 0 #000,
      calc(-3 * var(--w)) 0 #000, calc(-4 * var(--w)) -10px #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000, calc(-8 * var(--w)) 0 #000,
      calc(-9 * var(--w)) 0 #000;
  }

  54.54% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000, calc(-2 * var(--w)) 0 #000,
      calc(-3 * var(--w)) 0 #000, calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) -10px #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000, calc(-8 * var(--w)) 0 #000,
      calc(-9 * var(--w)) 0 #000;
  }

  63.63% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000, calc(-2 * var(--w)) 0 #000,
      calc(-3 * var(--w)) 0 #000, calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) -10px #000, calc(-7 * var(--w)) 0 #000, calc(-8 * var(--w)) 0 #000,
      calc(-9 * var(--w)) 0 #000;
  }

  72.72% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000, calc(-2 * var(--w)) 0 #000,
      calc(-3 * var(--w)) 0 #000, calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) -10px #000, calc(-8 * var(--w)) 0 #000,
      calc(-9 * var(--w)) 0 #000;
  }

  81.81% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000, calc(-2 * var(--w)) 0 #000,
      calc(-3 * var(--w)) 0 #000, calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000, calc(-8 * var(--w)) -10px #000,
      calc(-9 * var(--w)) 0 #000;
  }

  90.90% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000, calc(-2 * var(--w)) 0 #000,
      calc(-3 * var(--w)) 0 #000, calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000, calc(-8 * var(--w)) 0 #000,
      calc(-9 * var(--w)) -10px #000;
  }
}

@media (max-width: 576px) {
  .sharePop > div {
    max-width: 300px;
    margin-inline: auto;
  }
}

/* *************************************** herart line ****************************************** */

.heartLine {
  display: flex;
  align-items: center;
  text-align: center;
  color: #ccc;
  /* Light gray color for the lines */
  max-width: 350px;
  margin-inline: auto;
}

.heartLine::before,
.heartLine::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
  /* Light gray color for the lines */
}

.heartLine .heart {
  color: var(--primary-color);
  /* Red color for the heart */
  font-size: 24px;
  margin: 0 10px;
  /* Space on either side of the heart */
}

/* ************************************** chat ********************************************** */
.chatScrollableDiv {
  height: unset !important;
}

.typingIndicatorCOntainer {
  background-color: var(--header-color);
}

.typingIndicator > span {
  width: 12px !important;
  height: 12px !important;
}

.emojiPicker {
  max-height: 400px;
  bottom: 37px;
}

.messageBox textarea {
  resize: none;
}

/* ***************************** Moal ************************************************* */
.uppdatePasswordModal .modal-content {
  border-radius: 4px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.dropdown-item:hover,
.dropdown-item:active,
.dropdown-item:focus {
  background-color: var(--hover-color);
  color: unset;
}

/* *************************** Faqs *************************************** */
.faqsAccordion .accordion-button {
  background-color: transparent;
}

.faqsAccordion .accordion-button:not(.collapsed) {
  background-color: var(--primary-color);
  color: var(--white-color);
}
.faqsAccordion .accordion-button:not(.collapsed)::after {
  color: var(--white-color);
}

.faqsAccordion .accordion-button:focus {
  box-shadow: none;
}

.bottomTabProfileIcon {
  width: 25px;
  height: 25px;
}

/* ************************************ popup **************************************************** */
.popup {
  animation: popup 0.7s;
}

@keyframes popup {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  60% {
    transform: scale(1.1);
  }

  70% {
    transform: scale(1.2);
  }

  80% {
    transform: scale(1);
  }

  90% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.searchIcon {
  transform: translate(-12px, -50%);
}
