.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-bottom: 0;
}

.dot-medium {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-bottom: 0;
}

.dot-online {
  background-color: #00de13;
}

.bg-secondary-default {
  background-color: rgb(var(--bs-secondary-rgb));
}

.mainBodyHeight {
  height: calc(100vh - 100px) !important;
}

.text-color-unset {
  color: unset !important;
}

.text-extraLarge {
  font-size: 40px;
}

.form-control:focus {
  border-color: var(--primary-color);
}

.icon-small {
  width: 16px;
  height: 16px;
}

.icon-large {
  width: 40px;
  height: 40px;
}

/* Overlay loader */

/* #### Pre Loader #### */
.pre-loader-wrapper {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;
  transform: scale(0.99);
  opacity: 0;
  visibility: hidden;
  z-index: -9999;

  .icon {
    height: 64px;
  }

  &.show {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
}

.loading-bar {
  display: block;
  position: relative;
  height: 4px;
  width: 150px;
  overflow: hidden;
  background: #4d4d4d;
}

.loading-bar::after {
  content: "";
  width: 40%;
  height: 100%;
  background: linear-gradient(90deg, #475cc7 0%, #ae47ff 100%);
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 1.2s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }

  100% {
    width: 60%;
    left: 100%;
    transform: translateX(0%);
  }
}

.lh-12 {
  line-height: 20px;
}

.lh-20 {
  line-height: 20px;
}

.width-20 {
  width: 20px;
}

.width-22 {
  width: 22px;
}

.width-100 {
  width: 100px;
}
.width-175 {
  width: 175px;
}

.height-100 {
  height: 100px;
}

.height-20 {
  height: 20px;
}
.height-22 {
  height: 22px;
}

.max-h-50 {
  max-height: 50px;
}

.max-h-120 {
  max-height: 120px;
}

.max-h-300 {
  max-height: 300px;
}

.min-w-50 {
  min-width: 50px;
}
.min-w-70 {
  min-width: 70px;
}

.min-w-100 {
  min-width: 100px;
}

.max-w-150 {
  max-width: 150px;
}

.max-w-300 {
  max-width: 300px;
}

.max-w-400 {
  max-width: 400px;
}

.max-w-500 {
  max-width: 400px;
}

.max-w-unset {
  max-width: unset;
}

.p-block-1 {
  padding-block: 1px;
}

.likeIcon {
  color: #2684ff;
}
.loveIcon {
  color: red;
}
.laughIcon {
  color: orange;
}
.supportIcon {
  color: #c90000;
}
.informationIcon {
  color: #ffa500;
  vertical-align: text-top;
}

input {
  height: 40px !important;
}

input::placeholder {
  color: #0000005e !important;
}

.cs--react-tooltip {
  /* background-color: black !important; */
  /* font-size: 0.75rem !important;
  letter-spacing: 0.3px;
  line-height: 1.25; */
  padding: 6px 10px !important;
  max-width: 340px !important;
  z-index: 9999;
  word-break: break-all !important;
  /* b {
      font-weight: 600;
  }
  ul {
      margin-bottom: 0 !important;
      padding-top: 4px !important;
      padding-bottom: 8px !important;
      padding-left: 1.5rem !important;
  } */
}
